<template>
    <div style="margin:0 10px">
        <router-view></router-view> 
        <div class="card">
            <!-- search -->
            <div class="search d-flex a-center j-sb">
                <div class="d-flex a-center">
                    <!-- <div style="width:160px;margin-right:10px;">
                        <el-input type="text" v-model="query.a" placeholder="角色名称"></el-input>  
                    </div>
                    <el-button type="primary">查询</el-button> -->
                    <el-button type="primary" @click="$router.push({name:'role_add'})">+ 添加</el-button>    
                </div>

            </div>

            <!-- handle -->
            <div class="handle"></div>

            <!-- table -->
            <div class="table" style="margin-top:20px;">
                <el-table
                    :data="tableData"
                    style="width: 100%;"
                    v-loading="loading"
                    element-loading-background="rgba(255,255,255, 0.6)"
                    :header-cell-style="{background:'#f8f8f9',color:'#606266',borderBottom:'1px solid #e8eaec',padding:'8px 0',fontWeight:'700'}"
                >
                    <el-table-column prop="name" label="角色名称" align="center"></el-table-column>
                    <el-table-column prop="description" label="角色描述" align="center"></el-table-column>
                    <el-table-column prop="" label="操作" align="center" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="()=>$router.push({name:'role_auth',query:scope.row})">权限</el-button>
                            <el-button type="text" size="small" @click="()=>$router.push({name:'role_edit',query:scope.row})">编辑</el-button>
                            <el-popconfirm
                                    title="是否确定删除？"
                                    confirm-button-text="确认删除"
                                    @confirm="handleDel(scope.row)"
                                >
                                    <el-button style="margin-left: 10px;" slot="reference" type="text">删除</el-button>
                                </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- page -->
                <!-- <div style="margin-top:20px;text-align:center">
                    <el-pagination
                        background
                        layout="total, prev, pager, next, sizes"
                        :total="total"
                        :page-size="query.limit"
                    >
                    </el-pagination>
                </div> -->
            </div>
            
        </div>
    </div>
</template>

<script> 
import {lists,del} from '@/request/api/role'
export default {
    data(){
        return {
            tableData: [],
            query:{a:''},
            total:0 , //列表总数
            loading:false,
            isEdit:false ,//编辑状态
        }
    },
    watch:{
        $route(val){
            //返回刷新
            if(val.name=='role') {
                this.init()
            }
        }
    },
    created(){
        this.init()
    },

    methods:{
        async init(){
            this.loading=true
            lists({}).then(res=>{
                this.tableData = res.data
            }).finally(()=>{
                this.loading=false
            })
        },
        handleDel(row){
            del({ids:[row.id]}).then(res=>{
                if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'});
                this.init()
                this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
            })
        },
    }
}
</script>


<style scoped lang="less">


</style>

